import React from 'react';
import Tab from './Tab';
import '../css/components/Tabs.css';

function Tabs(props) {
    return (
        <div className="tabs">
          <ul>
            {props.tabArray.map(tab => {
                return (
                    <Tab id={tab.id} tabName={tab.name} clickFn={props.switchTabs} icon={tab.icon} isActive={tab.isActive}/>
                );
            })}
          </ul>
        </div>
    );
}

export default Tabs;