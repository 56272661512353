import React from 'react';
import '../css/components/ContentBlock.css';

function ContentBlock(props) {
    const section = props.section;

    if(section === "experiences") {
        return (
            <div className="block-content">
                <div className="img-div">
                    <img src={props.icon} alt={props.company}/>
                </div>
                <div className="right-content">
                    <h2 className="position">{props.position}</h2>
                    <h4 className="company">{props.company}</h4>
                    <h5 className="duration">{props.duration}</h5>
                    <p>{props.content}</p>
                </div>
            </div>
        )
    }else {
        return (
            <div className="block-content">
                <div className="right-content">
                    <h2 className="name">{props.name}</h2>
                    <h4 className="technologies">{props.technologies}</h4>
                    <h5 className="duration">{props.duration}</h5>
                    <p>{props.content}</p>
                    {props.projectImg && <img className="project-img" src={props.projectImg} alt={props.name} />}
                    {
                        props.projectLinkName && 
                        props.projectLink && 
                        <a className="project-link" href={props.projectLink}>{props.projectLinkName}</a>
                    }
                </div>
            </div>
        )
    }
}

export default ContentBlock;