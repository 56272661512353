import React from 'react';
import Bookmarks from './Bookmarks';
import '../css/components/BookmarkContainer.css';

function BookmarkContainer(props) {
    return (
        <div className="url-bookmark-container">
            <div className="url">
            {`/${props.selectedTab}`}
            </div>
            <div className="contacts-bookmarks">
                <Bookmarks />
            </div>
        </div>
    );
}

export default BookmarkContainer;