import '../css/pages/Other.css';

const Others = () => {
    return (
        <div className="other-content">
            Under construction :)
        </div>
    )
};

export default Others;