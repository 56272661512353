import { Link } from "react-router-dom";
import React from 'react';
import '../css/components/Tab.css';

function Tab(props) {
    const linkStyle = {
        color: "black"
    }

    return (
        <Link to={`/${props.id}`} className={linkStyle}>
            <li id={props.id} className={(props.isActive) ? "active" : "inactive"} onClick={(e) => props.clickFn(e, props.id)}>
                <img src={props.icon} /> 
                <span>{`  |  ${props.tabName}`}</span>
            </li>
        </Link>
    );
}

export default Tab;