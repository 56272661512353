import { React } from "react-router-dom";
import ContentBlock from "../components/ContentBlock";
import capstone from '../icons/projects/capstone.png';
import '../css/pages/Projects.css';

const projectsArray = [
    {
        name: "Capstone Project",
        technologies: "TypeScript, React.js, Python, Flask, Electron.js, ESP32 camera",
        duration: "January 2022 - August 2022",
        content: `Created a restaurant management system featuring a management software application and a hardware 
                component. The management software is an Electron application written in Typescript React and the 
                hardware component features an ESP32 camera module for image detection technology.`,
        projectImg: capstone,
        projectLinkName: "Github",
        projectLink: "https://github.com/ChicoRao/ENSC405-Capstone"
    },
    {
        name: "Nihon-Go",
        technologies: "React.js, Javascript, CSS",
        duration: "July 2020 - December 2020",
        content: `Implemented a fun personal project to help people learn Japanese alphabets`,
        projectImg: "",
        projectLinkName: "Try it out",
        projectLink: "https://chicorao.github.io/nihon-go"
    },
    {
        name: "PacMacro",
        technologies: "Node.js, Express.js, JavaScript, HTML5",
        duration: "January 2020 - March 2020",
        content: `Worked with other SFU Surge members on maintaining and improving PacMacro, a 
                real life macro version of PacMan played by freshman during frosh week.`,
        projectImg: "",
        projectLinkName: "Github",
        projectLink: "https://github.com/pacmacro/pm-server-node"
    },
    {
        name: "NeuroKinetic",
        technologies: "Swift, Firebase",
        duration: "September 2019 - December 2019",
        content: `Developed an iOS app that aims to help Parkinson's patients 
                with their symptoms through three simple games.`,
        projectImg: "",
        projectLinkName: "Github",
        projectLink: "https://github.com/Antonio-Kim/CMPT275Project"
    }
]

const Projects = () => {
    return (
        <div className="projects-content">
            <h1>Projects</h1>
            {projectsArray.map(project => {
                return (
                    <ContentBlock
                        section={"projects"} 
                        name={project.name}
                        technologies={project.technologies}
                        duration={project.duration}
                        content={project.content}
                        projectImg={project.projectImg}
                        projectLinkName={project.projectLinkName}
                        projectLink={project.projectLink}
                    />
                )
            })}
        </div>
    )
};

export default Projects;